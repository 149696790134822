import { IBundle } from "@tm/morpheus"

import ApiBundle from "@bundles/api"
import BasketBundle from "@bundles/basket"
import CarmunicationBundle from "@bundles/carmunication"
import CisBundle from "@bundles/cis"
import CompilationsBundle from "@bundles/compilations"
import CrmBundle from "@bundles/crm"
import DamageCalculationAswegasBundle from "@bundles/damage-calculation-aswegas"
import DamageCalculationDatBundle from "@bundles/damage-calculation-dat"
import DamageCalculationEurotaxBundle from "@bundles/damage-calculation-eurotax"
import DamageCalculationGlassBundle from "@bundles/damage-calculation-glass"
import DmsBundle from "@bundles/dms"
import DutchOrderPlatesBundle from "@bundles/dutch-order-plates"
import DrivemotiveBundle from "@bundles/drivemotive"
import EdsBundle from "@bundles/eds"
import ErpBundle from "@bundles/erp"
import EurotaxBundle from "@bundles/eurotax"
import FastCalculatorBundle from "@bundles/fast-calculator"
import FastDtcBundle from "@bundles/fast-dtc"
import FastServiceBundle from "@bundles/fast-service"
import FeedbackBundle from "@bundles/feedback"
import MemoToolBundle from "@bundles/memo-tool"
import MiscBundle from "@bundles/misc"
import MvcBundle from "@bundles/mvc"
import NotificationsBundle from "@bundles/notifications"
import OffersBundle from "@bundles/offers"
import PartsBundle from "@bundles/parts"
import RepairTimesBundle from "@bundles/repair-times"
import SettingsBundle from "@bundles/settings"
import SindriBundle from "@bundles/sindri"
import PartslifeBundle from "@bundles/partslife"
import StandaloneBundle from "@bundles/standalone"
import TelematicsBundle from "@bundles/telematics"
import TelesalesBundle from "@bundles/telesales"
import TyresBundle from "@bundles/tyres"
import VehicleBundle from "@bundles/vehicle"
import VehicleRecordsBundle from "@bundles/vehicle-records"
import VouchersBundle from "@bundles/vouchers"
import WheelsBundle from "@bundles/wheels"
import WorktaskBundle from "@bundles/worktask"
import VefilesBundle from "@bundles/vefiles"

// Trader bundles
// import TraderAutonetBundle from "@bundles/trader/autonet"
import TraderBirnerBundle from "@bundles/trader/birner"
import TraderWmBundle from "@bundles/trader/wm"

export const bundles: Record<string, IBundle> = {
    "api": ApiBundle,
    "basket": BasketBundle,
    "carmunication": CarmunicationBundle,
    "cis": CisBundle,
    "compilations": CompilationsBundle,
    "crm": CrmBundle,
    "dmg-aswegas": DamageCalculationAswegasBundle,
    "dmg-dat": DamageCalculationDatBundle,
    "dmg-eurotax": DamageCalculationEurotaxBundle,
    "dmg-glass": DamageCalculationGlassBundle,
    "dms": DmsBundle,
    "dutch-order-plates": DutchOrderPlatesBundle,
    "drivemotive": DrivemotiveBundle,
    "eds": EdsBundle,
    "erp": ErpBundle,
    "eurotax": EurotaxBundle,
    "fast-calculator-next": FastCalculatorBundle,
    "fast-dtc": FastDtcBundle,
    "fast-service": FastServiceBundle,
    "feedback": FeedbackBundle,
    "memo-tool": MemoToolBundle,
    "misc": MiscBundle,
    "mvc": MvcBundle,
    "notifications": NotificationsBundle,
    "offers": OffersBundle,
    "parts": PartsBundle,
    "repair-times": RepairTimesBundle,
    "settings": SettingsBundle,
    "sindri": SindriBundle,
    "partslife": PartslifeBundle,
    "standalone": StandaloneBundle,
    "telematics": TelematicsBundle,
    "telesales": TelesalesBundle,
    "tyres": TyresBundle,
    "vehicle": VehicleBundle,
    "vehicle-records": VehicleRecordsBundle,
    "vouchers": VouchersBundle,
    "wheels": WheelsBundle,
    "worktask": WorktaskBundle,
    "vefiles": VefilesBundle,

    // Trader bundles
    // "autonet": TraderAutonetBundle, // TODO: not in use anymore - check if it can be removed permanantly
    "birner": TraderBirnerBundle,
    "wm": TraderWmBundle,
}
