export const WebserviceUrl = {
    Vouchers: "/data/TM.Next.Vouchers",
    Vefiles: "/data/TM.Next.Vouchers/vefiles",
    Authority: "/data/TM.Next.Authority",
    RepairShops: "/data/TM.Next.RepairShops.Portal/RepairShops",
    FastService: "/data/TM.Next.FastCalculator/fastservice",
    FastCalculator: "/data/TM.Next.FastCalculator/FastCalculator",
    Articles: "/data/TM.Next.Catalog/Articles",
    UniArticle: "/data/TM.Next.UniArticles/Articles/UniArticles",
}
