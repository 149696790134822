import { EmbeddedPosition, OAuthConfig, OAuthTokenIds, SystemType, UserSettingsDefaults } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { BrowserCompabilityCheckConfig } from "../../components/BrowserCompabilityCheck"
import { BaseConfig } from "./loadBaseConfig"
import { handleConfigurationLoadProgress, handleConfigurationNotFound } from "./loadingProgress"

export async function loadConfiguration(baseConfig: BaseConfig, type: ConfigType, identifier: string, hostname: string, traderId: string | undefined): Promise<AppConfig | undefined> {
    try {
        handleConfigurationLoadProgress()

        const { service: baseUrl, systems } = baseConfig
        if (systems && !systems.some(x => x == identifier)) {
            return
        }

        if (type == "LOGIN") {
            return await ajax({
                url: `${baseUrl}/GetLoginConfig`,
                body: {
                    identifier,
                    hostname,
                    traderId,
                }
            })
        }

        return await ajax({
            url: localStorage.getItem("CONFIG_ACTION") || `${baseUrl}/GetAppConfig`, // To use another endpoint for getting the config
            authorization: getStoredAuthorization(),
        })
    }
    catch {
        console.error(`Configuration file '${identifier}' could not be loaded.`)
        handleConfigurationNotFound(identifier)
    }
}

export type ConfigType = "APP" | "LOGIN"

// TODO: merge with type AppConfig from @tm/models
export type AppConfig = {
    authentication?: {
        applicationId: string
        applicationType: number
        catalogId: number
    }
    login: {
        serviceUrl: string
        authId?: string | { default: string }
        url?: string
        errorLogin?: string
        externalAuthentication?: Record<string, Record<string, string>>
        props?: {
            loginBackground?: string
            /**Is used as fav-icon. */
            dealerIconUrl?: string
            conceptStyle?: boolean
            loginMessageId?: string | number
            anonymousLogin?: boolean
            errorLoginRedirectUrl?: string
        }
        browserCompatibilityCheck?: BrowserCompabilityCheckConfig
        externalAuthenticationOAuth?: OAuthConfig
    }
    logout?: {
        url: string
    }
    params: {
        env: "dev" | "test" | "int" | "prod"
        authorityServiceUrl?: string
        erpServiceUrl: string
        keyValueStoreServiceUrl: string
        repairShopServiceUrl: string
        stateServiceUrl: string
        translationsServiceUrl: string
        disableAnalytics: boolean
        conceptStyle?: boolean
        tokenHandler?: Record<string, string>
        externalScripts?: Partial<Record<string, EmbeddedPosition>>
        userSettingsDefaults: UserSettingsDefaults
        oAuthConfiguration?: Partial<Record<OAuthTokenIds, OAuthConfig>>
    }
    styles: string
    icons: string
    locale: string
    languagesWhitelist?: Array<string>
    /** If "languagesWhitelist" is available, the blacklist is ignored */
    languagesBlacklist?: Array<string>
    title: string
    favicon: string
    disableRedirectToExternalSystem: boolean
    systemType: SystemType
    pwa?: {
        icons: {
            "128": string
            "512": string
        }
        backgroundColor: string
        themeColor: string
        startUrl: string
        shortName?: string
        name?: string
        scope?: string
    }
}
