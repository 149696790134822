import { useLocalization } from "@tm/localization"
import { OAuthConfig } from "@tm/models"
import { generatePKCECodeVerifier, getOAuthClient, storeOAuthData, uniqueId } from "@tm/utils"
import { useEffect, useMemo } from "react"
import { Message } from "./Message"

type Props = {
    config: OAuthConfig
    isProd: boolean
}

export function AuthorizeFlowComponent({ config, isProd }: Props) {
    const { scope, extraParams } = config

    const { translateText } = useLocalization()

    const client = useMemo(() => getOAuthClient(config, isProd), [config, isProd])

    useEffect(() => {
        (async () => {
            const redirectUri = window.location.origin + window.location.pathname
            const state = uniqueId()
            const codeVerifier = await generatePKCECodeVerifier()
            storeOAuthData({ redirectUri, state, codeVerifier })

            document.location.href = await client.authorizationCode.getAuthorizeUri({
                redirectUri,
                state,
                codeVerifier,
                scope,
                extraParams,
            })
        })()
    }, [client, scope, extraParams])

    return (
        <Message
            title={translateText(13871)}
            text={translateText(13872)}
        />
    )
}
