import { Authority } from "@tm/data"
import { useLocalization } from "@tm/localization"
import { OAuthConfig, OAuthTokenIds } from "@tm/models"
import { deleteStoredOAuthData, getOAuthClient, getStoredOAuthData } from "@tm/utils"
import { useEffect, useMemo, useState } from "react"
import { Message } from "./Message"

type Props = {
    config: OAuthConfig
    isProd: boolean
    tokenId: OAuthTokenIds
}

function isValidWindowOpener(opener: any): opener is { postMessage: Window["postMessage"] } {
    return !!opener && typeof opener === "object"
        && "postMessage" in opener && typeof opener.postMessage === "function"
}

function postMessage(status: "success" | "error") {
    if (isValidWindowOpener(window.opener)) {
        window.opener.postMessage({ type: "oauth-status", status }, window.origin)
    }
}

export function ValidateFlowComponent({ config, isProd, tokenId }: Props) {
    const { scope, extraParams } = config

    const { translateText } = useLocalization()

    const [state, setState] = useState<"loading" | "success" | "error">("loading")

    const client = useMemo(() => getOAuthClient(config, isProd), [config, isProd])

    useEffect(() => {
        (async () => {
            const { redirectUri, state, codeVerifier } = getStoredOAuthData() ?? {}
            if (!redirectUri || !state || !codeVerifier) {
                setState("error")
                postMessage("error")
                return
            }

            deleteStoredOAuthData()

            try {
                const token = await client.authorizationCode.getTokenFromCodeRedirect(
                    document.location.href,
                    {
                        redirectUri,
                        state,
                        codeVerifier,
                    }
                )

                await Authority.storeOAuthToken({
                    tokenId,
                    clientId: client.settings.clientId,
                    tokenEndpoint: await client.getEndpoint("tokenEndpoint"),
                    accessToken: token.accessToken,
                    expiryDateAccessToken: token.expiresAt ? new Date(token.expiresAt) : undefined,
                    refreshToken: token.refreshToken ?? undefined,
                })

                setState("success")
                postMessage("success")
            }
            catch {
                setState("error")
                postMessage("error")
            }
        })()
    }, [tokenId, client, scope, extraParams])

    function getTranslations(): { title: string, text: string } | undefined {
        switch (state) {
            case "loading":
                return {
                    title: translateText(13873),
                    text: translateText(13874),
                }
            case "success":
                return {
                    title: translateText(13875),
                    text: translateText(13876),
                }
            case "error":
                return {
                    title: translateText(13877),
                    text: translateText(13878),
                }
            default:
                return undefined
        }
    }

    const translations = getTranslations()

    if (!translations) {
        return null
    }

    return (
        <Message
            title={translations.title}
            text={translations.text}
        />
    )
}
