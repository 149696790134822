import { Loader } from "@tm/components"
import { AlertNotification, SizeProvider, ThemeProvider, WorkTaskProvider, createUserProvider, loadStyleThemes, useModuleNavigation } from "@tm/context-distribution"
import { appendControlsConfig } from "@tm/controls"
import { SystemType, channel } from "@tm/models"
import Morpheus, { IContext } from "@tm/morpheus"
import { TmaEventTracking, initMainHistory } from "@tm/utils"
import { createBrowserHistory } from "history"
import { ReactNode, Suspense, useMemo } from "react"
import { createPortal, render } from "react-dom"
import { QueryClientProvider } from "react-query"
import { Route, Router, Switch } from "react-router"
import { RecoilRoot } from "recoil"
import BrowserCompabilityCheck from "../components/BrowserCompabilityCheck"
import { CheckUserCredentialsSet } from "../components/CheckUserCredentialsSet"
import { Drawer } from "../components/Drawer"
import Modal from "../components/Modal"
import { SnackbarProvider } from "../components/Snackbar"
import { UpdateChecker } from "../components/UpdateChecker"
import { AppMessageCenter } from "../components/app-message-center"
import ErrorComponent from "../components/error"
import { getCatalogTexts, getCatalogTextsLogin } from "../data/translations"
import { initMock } from "../mocks"
import * as KeyValueStoreRepo from "../repositories/keyValueStore"
import * as ViewStateRepo from "../repositories/viewstate"
import { createQueryClient } from "./data/createQueryClient"
import { handleConfiguration } from "./data/handleConfiguration"
import { initCachingWhitelist } from "./data/initCachingWhitelist"
import { loadAssets } from "./data/loadAssets"
import { loadBaseConfig } from "./data/loadBaseConfig"
import { AppConfig } from "./data/loadConfiguration"
import { loadSprites } from "./data/loadSprites"
import { loadStyles } from "./data/loadStyles"
import { resetLoadProgress } from "./data/loadingProgress"
import { registerAjaxErrorHandler } from "./data/registerAjaxErrorHandler"
import { appendExternalScripts } from "./document/appendExternalScripts"
import { initPortalLocations } from "./document/initPortalLocations"
import { redirectToExternalSystem } from "./document/redirectToExternalSystem"
import { setDocumentMetadata } from "./document/setDocumentMetadata"
import { setupProgressiveWebApp } from "./document/setupProgressiveWebApp"
import { getLocalizationProvider } from "./localization/getLocalizationProvider"
import { setNextShellLanguage } from "./localization/setNextShellLanguage"
import { initLogging } from "./logging/initLogging"
import { setBrowserTabId } from "./logging/setBrowserTabId"
import { getLoginComponents } from "./login/getLoginComponents"
import { registerLogoutContainerAction } from "./login/handleLogout"
import { SimplePortalRoutes, initializeSimplePortal } from "./simplePortal"
import { getStylesUrl } from "./theming/getStylesUrl"
import { getMuiThemeUrl, getThemeUrl } from "./theming/getThemeUrl"
import { useHasConcept } from "./theming/useHasConcept"

export function initializePortal(instantiateMorpheus: (config: any) => Morpheus) {
    // The simple portal does only include some basic features like theming, localization and routing and can therefor only be used for specif routes/components.
    // Also currently it requires a token to be present in the session storage to load the app config.
    if (SimplePortalRoutes.some(route => window.location.pathname.startsWith(route))) {
        initializeSimplePortal()
    }
    else {
        initialize(instantiateMorpheus)
    }
}

async function initialize(instantiateMorpheus: (config: any) => Morpheus) {
    setBrowserTabId()

    initLogging()

    const history = createBrowserHistory()
    initMainHistory(history)
    initPortalLocations(history)

    const baseConfig = await loadBaseConfig()
    initCachingWhitelist()

    registerAjaxErrorHandler(history)
    initMock()

    const configuration = await handleConfiguration(history, baseConfig)

    if (!configuration) {
        return
    }

    const { appConfig, catalog, loginMatch, configType, languageId } = configuration

    setNextShellLanguage(languageId)

    setupProgressiveWebApp(appConfig)

    if (!appConfig.disableRedirectToExternalSystem) {
        channel("GLOBAL").subscribeOnce("USER/CONTEXT_LOADED", redirectToExternalSystem.bind(null, history))
    }

    // disable tracking when it's disabled in the config
    if (appConfig.params.disableAnalytics) {
        TmaEventTracking.disableTracker()
    }

    // remember the authentication infos
    const { authId } = appConfig.login;
    (window as any).auth = authId ? { authId } : appConfig.authentication

    const hasConcept = useHasConcept(appConfig)

    loadStyles(getStylesUrl(appConfig.styles, hasConcept))
    loadSprites(appConfig.icons)

    ViewStateRepo.initialize(appConfig.params.stateServiceUrl)
    KeyValueStoreRepo.initialize(appConfig.params.keyValueStoreServiceUrl)

    registerLogoutContainerAction(history, appConfig, catalog)

    const morpheus = instantiateMorpheus(appConfig)

    appendExternalScripts(appConfig)
    appendControlsConfig(appConfig)

    // Load login and login error component
    const [LoginComponent, LoginErrorComponent] = loginMatch ? (getLoginComponents(appConfig, loginMatch, catalog, languageId, appConfig.login.serviceUrl) ?? []) : []

    await loadStyleThemes(getThemeUrl(appConfig.styles, hasConcept), getMuiThemeUrl(appConfig.styles, hasConcept))

    await loadAssets(morpheus)

    const LocalizationProvider = await getLocalizationProvider(
        languageId,
        appConfig,
        (configType === "LOGIN" ? getCatalogTextsLogin : getCatalogTexts).bind(undefined, appConfig.params.translationsServiceUrl, authId)
    )

    const UserProvider = appConfig.params.authorityServiceUrl ? await createUserProvider({
        authorityServiceUrl: appConfig.params.authorityServiceUrl,
        repairShopServiceUrl: appConfig.params.repairShopServiceUrl,
        externalAuthentication: appConfig.login.externalAuthentication,
        userSettingsDefaults: appConfig.params.userSettingsDefaults
    }) : undefined

    setDocumentMetadata(appConfig, window.userContext)

    const modals = document.querySelector("#modals")

    morpheus.init(history)

    let modalElement: Modal | null
    let modalInitialOpened = false
    const modalView = morpheus.createView("1", action => {
        if (modalElement) {
            modalElement.toggle(action)
        }
        else {
            modalInitialOpened = action === "OPEN"
        }
    })

    resetLoadProgress()

    render((
        <ThemeProvider>
            <LocalizationProvider>
                {appConfig.login.browserCompatibilityCheck && <BrowserCompabilityCheck {...appConfig.login.browserCompatibilityCheck} />}
                <UpdateChecker />
                <Router history={history}>
                    <Switch>
                        <Route path="/login" component={LoginComponent} />
                        <Route path="/login-error" component={LoginErrorComponent} />
                        <QueryClientProvider client={createQueryClient()}>
                            <RecoilRoot>
                                <SnackbarProvider>
                                    {
                                        !!UserProvider &&
                                        <Suspense fallback={<Loader />}>
                                            <UserProvider>
                                                <SizeProvider>
                                                    <AlertNotification erpServiceUrl={appConfig.params.erpServiceUrl} />
                                                    {wrapWithWorktaskProvider(
                                                        appConfig,
                                                        morpheus.context,
                                                        <>
                                                            <MainContent morpheus={morpheus} />
                                                            {
                                                                !!modals &&
                                                                createPortal((
                                                                    <Modal name="1" opened={modalInitialOpened} ref={el => modalElement = el}>
                                                                        {modalView}
                                                                    </Modal>
                                                                ), modals)
                                                            }
                                                            {!!modals && createPortal(<AppMessageCenter />, modals)}
                                                            <Drawer morpheus={morpheus} />
                                                        </>
                                                    )}
                                                    <CheckUserCredentialsSet />
                                                </SizeProvider>
                                            </UserProvider>
                                        </Suspense>
                                    }
                                </SnackbarProvider>
                            </RecoilRoot>
                        </QueryClientProvider>
                    </Switch>
                </Router>
                <ErrorComponent />
            </LocalizationProvider>
        </ThemeProvider>
    ), document.querySelector("#app"))
}

function wrapWithWorktaskProvider(config: AppConfig, context: IContext, content: ReactNode) {
    const globalPages: string[] = []
    context.routes.forEach(({ path }) => {
        if (/(^\/\:|^\/$)/.test(path)) {
            return
        }
        const firstFragment = path.split("/")[1]
        if (!globalPages.includes(firstFragment)) {
            globalPages.push(firstFragment)
        }
    })
    if (!config.systemType || config.systemType == SystemType.Next) {
        return <WorkTaskProvider globalPages={globalPages}>{content}</WorkTaskProvider>
    }

    return content
}

function MainContent(props: { morpheus: Morpheus }) {
    const { morpheus } = props
    const { showTab } = useModuleNavigation()

    // This has to be called with useMemo. useEffects is too late, when you reload the portal
    useMemo(() => morpheus.addBroadcastHandler("MODULE_OPENED", showTab), [showTab])

    return <>{morpheus.render()}</>
}
